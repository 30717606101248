import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EventCard = () => {
  const events = [
    {
      date: '03-10-2023',
      time: '8 AM to 2 PM',
      name: 'Sri Srinivasa Kalyana Mahotsavam',
      location: 'Kerschensteinerstraße 10, 21073 Hamburg'
    },
    {
      date: '28-04-2024',
      time: '8 AM to 8 PM',
      name: 'SRI SITA RAMA KALYANA MAHOTSAVAM and Ugadi Celebrations 2024',
      location: 'Breite Str. 10, 21244 Buchholz in der Nordheide, Deutschland'
    }
  ];

  return (
    <Container>
      <Row className="justify-content-center">
        {events.map((event, index) => (
          <Col md={6} key={index} className="mb-4" >
            <Card className="shadow-lg border border-primary" style={{height:"250px"}}>
              <Card.Body className="text-center">
                <Card.Title className="text-primary">{event.name}</Card.Title>
                <hr />
                <Card.Text>
                  <strong>Date:</strong> {event.date}
                </Card.Text>
                <Card.Text>
                  <strong>Time:</strong> {event.time}
                </Card.Text>
                <Card.Text>
                  <strong>Location:</strong> {event.location}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default EventCard;
