// src/components/Contact.js
import React from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import ComingSoon from "./ComingSoon";

const Contact = () => {
  return (
    <Container className="mt-5">
      {/* Carousel Section */}
      <div className="mb-4">
        <Carousel interval={6000} data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide1.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide2.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide3.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <Row>
        {/* Left Section */}
        <Col md={9}>
          <Card className="mb-3 shadow-sm">
            <Card.Header className="bg-primary text-white">
              Contact Us
            </Card.Header>
            <Card.Body>
              <Row>
              <Col md={5}>
                <div style={{ width: "100%", height: "100%" }}>
                  <iframe
                    title="Google Map"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0 }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.990484067233!2d2.292292615674465!3d48.858373179287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fefba382bf7%3A0x540fd1c894d1dfef!2sEiffel%20Tower!5e0!3m2!1sen!2sfr!4v1624378393041!5m2!1sen!2sfr"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </Col>
              <Col md={6}>
                <Form className="ml-4">
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter your email" />
                  </Form.Group>

                  <Form.Group controlId="formContactNumber" className="mb-3">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your contact number"
                    />
                  </Form.Group>

                  <Form.Group controlId="formMessage" className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter your message"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Right Section */}
        <Col md={3}>
          {/* Membership Form */}
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h5 className="p-2 bg-primary text-white">Email address</h5>
                  <Form.Control type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>

          {/* Additional Sections */}
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <h5 className="mb-3 text-white bg-primary p-2">Quick Links</h5>
              <div className="my-2">
                <a href="#org-committee" className="p-2">
                  Organization Committee Members
                </a>
              </div>
              <div className="my-2">
                <a href="#event-calendar" className="p-2">
                  Event Calendar
                </a>
              </div>
              <div className="my-2">
                <a href="#become-member" className="p-2">
                  Become a Member
                </a>
              </div>
              <div className="my-2">
                <a href="#classifieds" className="p-2">
                  Classifieds
                </a>
              </div>
              <div className="my-2">
                <a href="#gallery" className="p-2">
                  Gallery
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
