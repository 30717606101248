import React from 'react';
import './LeadershipTeam.css';

const LeadershipTeam = () => {
  return (
    <div className="leadership-team-container">
      <img src="/team.jpeg" alt="Leadership Team" className="leadership-team-image" />
    </div>
  );
}

export default LeadershipTeam;
