import React from "react";
import { Modal, Button, Card } from "react-bootstrap";

const DarshanaModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      animation={true}
     
    >
      <div
        className="position-relative"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/dev.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "250px",
          width: "100%",
        }}
      >
        {/* Overlay Text on Image */}
        <Modal.Header closeButton className="position-absolute top-0 end-0"></Modal.Header>
        <div className="text-center text-white p-3 position-absolute top-50 start-50 translate-middle">
          <h3 className="fw-bold mb-0 fs-4">Sri Srinivasa Kalyana Mahotsavam 2024</h3>
        </div>
      </div>

      <Modal.Body className="p-4" style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Card className="border-0 shadow-sm">
          <Card.Body>
            <div className="text-muted fs-6 mb-3">
              <p>
                <strong>Date:</strong> <span className="text-primary">23rd November 2024</span>
              </p>
              <p>
                <strong>Time:</strong> <span className="text-primary">08:00 - 14:00</span>
              </p>
              <p>
                <strong>Venue:</strong> <span className="text-primary">H1/H2 Kerschensteinerstraße 10, Hamburg, 21073</span>
              </p>
            </div>
            <hr />
            <p className="text-justify fs-6 mb-4">
              Telugu Community Hamburg e.V., in collaboration with <strong>Tirumala Tirupathi Devasthanam (TTD)</strong> and <strong>APNRTS</strong>,
              invites you to the divine celebration of "Sri Srinivasa Kalyanam" in Hamburg, Germany. This event offers an opportunity to witness a
              celestial wedding ceremony accompanied by intricate rituals and devotional music.
            </p>
            <p className="fs-6"><strong>Events and Sevas offered:</strong></p>
            <ul className="ps-4 list-unstyled text-primary">
              <li>Suprabhatha Seva</li>
              <li>Thomala Seva</li>
              <li>Veda Aaseervachanam</li>
              <li>Kalyanam</li>
              <li>Visesha Kalyanam</li>
            </ul>
            <p className="fs-6 mt-4">
              We invite you to be a part of this event that fosters spiritual connection, cultural heritage, and community bonding.
            </p>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="primary"
          className="fw-bold px-4 py-2 fs-6 rounded-pill"
          href="https://buytickets.at/telugucommunityhamburg/1447893"
          target="_blank"
          rel="noopener noreferrer"
        >
          Enroll Now
        </Button>
        <Button
          variant="outline-secondary"
          className="fw-bold px-4 py-2 fs-6 rounded-pill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DarshanaModal;
