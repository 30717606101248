// src/components/Membership.js
import React from 'react';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import ComingSoon from './ComingSoon';
const Membership = () => {
  return (
    <Container className="mt-5">
      {/* Carousel Section */}
      <div className="mb-4">
        <Carousel interval={6000} data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide1.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide2.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={`${process.env.PUBLIC_URL}/slide3.jpeg`}
              height={"550px"}
              width={"auto"}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <Row>
        {/* Left Section */}
        <Col md={9}>
        <Card className="mb-3 shadow-sm">
            <Card.Header className="bg-primary text-white">
              Membership
            </Card.Header>
            <Card.Body>
             Coming Soon
            </Card.Body>
          </Card>
        </Col>

        {/* Right Section */}
        <Col md={3}>
          {/* Membership Form */}
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <h5 className="p-2 bg-primary text-white">Email address</h5>
                  <Form.Control type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>

          {/* Additional Sections */}
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <h5 className="mb-3 text-white bg-primary p-2">Quick Links</h5>
              <div className="my-2">
                <a href="#org-committee" className="p-2">
                  Organization Committee Members
                </a>
              </div>
              <div className="my-2">
                <a href="#event-calendar" className="p-2">
                  Event Calendar
                </a>
              </div>
              <div className="my-2">
                <a href="#become-member" className="p-2">
                  Become a Member
                </a>
              </div>
              <div className="my-2">
                <a href="#classifieds" className="p-2">
                  Classifieds
                </a>
              </div>
              <div className="my-2">
                <a href="#gallery" className="p-2">
                  Gallery
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Membership;
