import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-content">
          {/* Useful Links */}
          <Col md={4} className="footer-section">
            <h5 className="footer-heading">Useful Links</h5>
            <ul className="footer-links">
              <li><a href="#about">About Us</a></li>
              <li><a href="#membership">Membership</a></li>
              <li><a href="#gallery">Gallery</a></li>
              <li><a href="#calendar">Calendar</a></li>
              <li><a href="#contact">Contact</a></li>
              {/* Add more links as needed */}
            </ul>
          </Col>

          {/* Follow Us */}
          <Col md={4} className="footer-section">
            <h5 className="footer-heading">Follow Us</h5>
            <div className="social-icons">
              <a href="https://www.facebook.com" className="social-icon">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://www.twitter.com" className="social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.instagram.com" className="social-icon">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </Col>

          {/* Contact Us */}
          <Col md={4} className="footer-section">
            <h5 className="footer-heading">Contact Us</h5>
            <p><FontAwesomeIcon icon={faEnvelope} />   contact@telugucommunityhamburg.de</p>
            <p><FontAwesomeIcon icon={faPhone} /> +49 176 59943022</p>
            <p> Redderkopell 14A Hamburg 22399</p>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col className="text-center">
            <p>&copy; 2024 Telugu Community Hamburg. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
