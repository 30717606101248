// src/components/More.js
import React from 'react';
import { Card } from 'react-bootstrap';

const More = () => {
  return (
    <Card className="mb-3">
      <Card.Header>More</Card.Header>
      <Card.Body>
        <p>Explore additional resources and information about our community.</p>
        <p>Find out more about our initiatives and ways to get involved.</p>
      </Card.Body>
    </Card>
  );
};

export default More;
