// src/components/Header.js
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";

function Header() {
  const headerStyle = {
    position: "relative",
    backgroundImage: `url('/header-bg.jpeg')`, // Set your background image path
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#fff",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust the overlay color and opacity as needed
    zIndex: 1,
  };

  const contentStyle = {
    position: "relative",
    zIndex: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap", // Ensure elements wrap on smaller screens
  };

  const brandTextStyle = {
    fontWeight: "bold",
    fontSize: "30px",
    color: "#fff",
    textAlign: "center", // Center text on smaller screens
  };

  const contactStyle = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    marginRight: "20px",
    fontSize: "14px",
    color: "#fff", // Ensure the text color is white for better contrast
    flexWrap: "wrap", // Ensure contact items wrap on smaller screens
  };

  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    marginLeft: "5px",
    fontSize: "15px",
    fontWeight: "bold",
  };

  return (
    <>
      <div style={headerStyle}>
        <div style={overlayStyle}></div>
        <Container fluid>
          <div style={contentStyle}>
            <Navbar.Brand
              as={Link}
              to="/"
              className="d-flex align-items-center flex-wrap"
            >
              <img
                src={`/telugualogo.jpeg`} // Use the image from the public folder
                alt="Logo"
                width="200"
                height="auto"
                className="d-inline-block align-top"
                style={{ borderRadius: "50%" }}
              />
              <div className="brand-text m-2" style={brandTextStyle}>
                TELUGU COMMUNITY HAMBURG E.V
                <br />
                <img
                  src={`/text-img.png`} // Use the image from the public folder
                  alt="Logo"
                  width="350"
                  height="auto"
                  className="d-inline-block align-top"
                ></img>
                {/* <span style={{ fontSize: "20px", fontWeight: "normal" , backgroundImage: `url('${process.env.PUBLIC_URL}/text-img.png')`}}>
                  తెలుగు కమ్యూనిటీ హాంబర్గ్
                </span> */}
              </div>
            </Navbar.Brand>
            <div style={contactStyle}>
              <div className="d-flex align-items-center">
                <FaEnvelope
                  style={{ width: "20px", height: "auto", fontWeight: "bold" }}
                />
                <a href="mailto:info@teluguvelugu.de" style={linkStyle}>
                  contact@telugucommunityhamburg.de
                </a>
              </div>
              <div className="d-flex align-items-center">
                <FaMapMarkerAlt
                  style={{ width: "20px", height: "auto", fontWeight: "bold" }}
                />
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Redderkopell 14A Hamburg 22399
                </span>
              </div>
              <div className="d-flex align-items-center">
                <FaPhone
                  style={{ width: "20px", height: "auto", fontWeight: "bold" }}
                />
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  +49 176 59943022
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Navbar expand="lg" style={{ backgroundColor: "#007b5e" }} variant="dark">
        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ color: "#ffff00", fontWeight: "bold" }}
              navbarScroll
            >
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/home"
              >
                HOME
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/about"
              >
                ABOUTUS
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/membership"
              >
                MEMEBERSHIP
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/gallery"
              >
                GALLERY
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/events"
              >
                EVENTS
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/home"
              >
                CALENDER
              </Nav.Link>

              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/contact"
              >
                CONTACT
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/leadership"
              >
                LEADERSHIP
              </Nav.Link>
              <Nav.Link
                style={{ color: "#ffff00", fontWeight: "bold" }}
                as={Link}
                to="/login"
              >
                LOGIN/SIGNUP
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
