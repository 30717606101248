// src/components/Calendar.js
import React from 'react';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";


const Calendar = () => {
  return (
    <Container className="mt-5">
    {/* Carousel Section */}
    <div className="mb-4">
      <Carousel interval={6000} data-bs-theme="dark">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/slide1.jpeg`}
            height={"550px"}
            width={"auto"}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/slide2.jpeg`}
            height={"550px"}
            width={"auto"}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={`${process.env.PUBLIC_URL}/slide3.jpeg`}
            height={"550px"}
            width={"auto"}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>

    <Row>
      {/* Left Section */}
      <Col md={9}>
        {/* Latest Section */}
        <Card className="mb-3 shadow-sm">
          <Card.Header className="bg-primary text-white">
            Telugu Community Hamburg
          </Card.Header>
          <Card.Body>
            Telugu Community Hamburg (TCH) e.V. – a non-profit organisation of
            people of Telugu origin residing in and around Hamburg ,Germany.
          </Card.Body>
        </Card>

        {/* News Section */}
        <Card className="mb-3 shadow-sm">
          <Card.Header className="bg-secondary text-white">
            Mission
          </Card.Header>
          <Card.Body>
            The mission of the association is to integrate and strengthen our
            bonds forging new friendships across all communities, cultures and
            borders. The association is also dedicated to create a home far
            from our motherland and in the process retain our cultural roots
            and traditions.
          </Card.Body>
        </Card>

        {/* Events Section */}
        <Card className="mb-3 shadow-sm">
          <Card.Header className="bg-success text-white">Purpose</Card.Header>
          <Card.Body>
            The purpose of the TCH e.V. association is to promote popular
            education, the international outlook,Tolerance in all areas of
            culture and the idea of international understanding. The
            association pursues exclusively and directly non-profit purposes
            within the meaning of this section“Tax-privileged purposes” of the
            tax code.
            <br/>
            <p style={{fontWeight:"bold"}} className="mt-2"> The purpose of the statutes is realised, in particular, by</p>
            <ol>
              <li>
                The regular, public organisation of cultural festivals and
                exhibitions on Telugu culture to promote exchange of cultures.
              </li>
              <li>
                Offering public, Telugu language lessons and Sanskrit for all
                interested children, young people and adults who are
                interested in the Telugu language and culture.
              </li>
              <li>
                German-Indo Integrity in teaching German to newcomers
                prioritises cultural inclusivity and linguistic proficiency,
                fostering unity and understanding among diverse communities.
              </li>
              <li>
                Guide and Support Local Authorities during emergencies,
                offering expertise and resources to enhance response
                capabilities and ensure community safety.
              </li>
              <li>
                Promote physical activities such as yoga and sports to enhance
                overall well- being and cultivate a healthy lifestyle among
                individuals of all ages. Through regular participation, foster
                physical fitness, mental resilience, and social cohesion
                within communities.
              </li>
            </ol>
          </Card.Body>
        </Card>

        {/* Photo Gallery Section */}
        {/* <Card className="mb-3 shadow-sm">
          <Card.Header className="bg-info text-white">Photo Gallery</Card.Header>
          <Card.Body>
            Content for Photo Gallery
          </Card.Body>
        </Card> */}
      </Col>

      {/* Right Section */}
      <Col md={3}>
        {/* Membership Form */}
        <Card className="mb-3 shadow-sm">
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h5 className="p-2 bg-primary text-white">Email address</h5>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>

        {/* Additional Sections */}
        <Card className="mb-3 shadow-sm">
          <Card.Body>
            <h5 className="mb-3 text-white bg-primary p-2">Quick Links</h5>
            <div className="my-2">
              <a href="#org-committee" className="p-2">
                Organization Committee Members
              </a>
            </div>
            <div className="my-2">
              <a href="#event-calendar" className="p-2">
                Event Calendar
              </a>
            </div>
            <div className="my-2">
              <a href="#become-member" className="p-2">
                Become a Member
              </a>
            </div>
            <div className="my-2">
              <a href="#classifieds" className="p-2">
                Classifieds
              </a>
            </div>
            <div className="my-2">
              <a href="#gallery" className="p-2">
                Gallery
              </a>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
  );
};

export default Calendar;
